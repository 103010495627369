@import './styles/variables.scss';

.App {
	text-align: center;
}

.App-logo {
	height: 36vmin;
	pointer-events: none;
}

.App-header {
	background-color: #fff;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 1.8vmin);
	color: #282c34;
	p {
		margin-top: -10px;
	}
}

.App-link {
	color: #61dafb;
}
